import { 
  DashboardOutlined,
  FileDoneOutlined,
  BulbOutlined,
  UsergroupAddOutlined,
  ExpandOutlined,
  UnorderedListOutlined,
  ControlOutlined,
  AppstoreOutlined,
  TagOutlined,
  ShoppingOutlined,
  ClusterOutlined,
  VideoCameraAddOutlined,
  NotificationOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'


const dashBoardNavTree = [{
  key: 'recipes',
  path: `${APP_PREFIX_PATH}/recipies`,
  title: 'RECIPES',
  icon: BulbOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'collections',
  path: `${APP_PREFIX_PATH}/collections`,
  title: 'MILESTONES',
  icon: ClusterOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'topics',
  path: `${APP_PREFIX_PATH}/topics`,
  title: 'TOPICS',
  icon: FileDoneOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'creators',
  path: `${APP_PREFIX_PATH}/creators`,
  title: 'CREATORS',
  icon: UsergroupAddOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'brands',
  path: `${APP_PREFIX_PATH}/brands`,
  title: 'BRANDS',
  icon: TagOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'list1',
  path: `${APP_PREFIX_PATH}/list`,
  title: 'LISTS',
  icon: UnorderedListOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'reels',
  path: `${APP_PREFIX_PATH}/reels`,
  title: 'REELS',
  icon: VideoCameraAddOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'notification',
  path: `${APP_PREFIX_PATH}/notification`,
  title: 'NOTIFICATIONS',
  icon: NotificationOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'products',
  path: `${APP_PREFIX_PATH}/products`,
  title: 'PRODUCTS',
  icon: ShoppingOutlined,
  breadcrumb: false,
  submenu: []
},
]

const ontology = [{
  key: 'configuration',
  path: `${APP_PREFIX_PATH}`,
  title: 'CONFIGURATION',
  icon: '',
  breadcrumb: false,
  submenu: [
    {
      key: 'configuration-ontology',
      path: `${APP_PREFIX_PATH}`,
      title: 'ONTOLOGY',
      icon: ControlOutlined ,
      breadcrumb: true,
      submenu: [
        {
          key: 'configuration-ontology-subtopic',
          path: `${APP_PREFIX_PATH}/sub-topic`,
          title: 'SUB-TOPICS',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'configuration-ontology-verbs',
          path: `${APP_PREFIX_PATH}/verbs`,
          title: 'VERBS',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'configuration-ontology-interest',
          path: `${APP_PREFIX_PATH}/interest`,
          title: 'INTERESTS',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
  
        {
          key: 'configuration-ontology-seasonalities',
          path: `${APP_PREFIX_PATH}/seasonalities`,
          title: 'SEASONALITY',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'configuration-ontology-activity',
          path: `${APP_PREFIX_PATH}/activity`,
          title: 'ACTIVITIES',
          icon: '',
          breadcrumb: false,
          submenu: []
        },
      ]
    }
  ]
}]





const navigationConfig = [
  ...dashBoardNavTree,
  ...ontology
]


export default navigationConfig;
