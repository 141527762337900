import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import PrivateRoute from './PrivateRoute/index'
import  ScrollToTop  from "./ScrollToTop.js";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <ScrollToTop />
      <Switch>
        <PrivateRoute path={`${APP_PREFIX_PATH}/list`} component={lazy(() => import(`./List1/ListView1`))} />
        <PrivateRoute path={`${APP_PREFIX_PATH}/reels`} component={lazy(() => import(`./Reels/ReelsVideoGallery`))} />
        {/* <Route exact path={`${APP_PREFIX_PATH}/recipies`} component={lazy(() => import(`./Recipies/RecipiesList`))} /> */}
        <PrivateRoute exact path={`${APP_PREFIX_PATH}/recipies`} component={lazy(() => import(`./Recipies/RecipiesList`))} />
        <PrivateRoute exact path={`${APP_PREFIX_PATH}/task/:id`} component={lazy(() => import(`./Recipies/RecipeDetailedView`))} />
        <PrivateRoute exact path={`${APP_PREFIX_PATH}/creators`} component={lazy(() => import(`./Creators/CreatorsList`))} />
        <PrivateRoute exact path={`${APP_PREFIX_PATH}/brands`} component={lazy(() => import(`./Brands/BrandsList`))} />
        <PrivateRoute exact path={`${APP_PREFIX_PATH}/collections`} component={lazy(() => import(`./Collections/CollectionList`))} />
        <PrivateRoute exact path={`${APP_PREFIX_PATH}/topics`} component={lazy(() => import(`./Topics/TopicsList`))} />
        <PrivateRoute path={`${APP_PREFIX_PATH}/sub-topic`} component={lazy(() => import(`./ontology/subtopic`))} />
        <PrivateRoute path={`${APP_PREFIX_PATH}/verbs`} component={lazy(() => import(`./ontology/Verbs`))} />
        <PrivateRoute path={`${APP_PREFIX_PATH}/interest`} component={lazy(() => import(`./ontology/Interest`))} />
        <PrivateRoute path={`${APP_PREFIX_PATH}/seasonalities`} component={lazy(() => import(`./ontology/Seasonality`))} />
        <PrivateRoute path={`${APP_PREFIX_PATH}/activity`} component={lazy(() => import(`./ontology/Activity`))} />
        <PrivateRoute path={`${APP_PREFIX_PATH}/notification`} component={lazy(() => import(`./Notification/PushNotification`))} />
        <PrivateRoute path={`${APP_PREFIX_PATH}/products`} component={lazy(() => import(`./Products/ProductsList`))} />

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/recipies`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);