import React from "react";
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown, Avatar,notification } from "antd";
import { connect } from 'react-redux';
// import { auth } from 'auth/FirebaseAuth';
import {  LogoutOutlined } from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { auth } from 'auth/FirebaseAuth';
const menuItem = [
	// {
	// 	title: "Edit Profile",
	// 	icon: EditOutlined ,
	// 	path: "/"
  //   },
    
  //   {
	// 	title: "Account Setting",
	// 	icon: SettingOutlined,
	// 	path: "/"
  //   },
  //   {
	// 	title: "Billing",
	// 	icon: ShopOutlined ,
	// 	path: "/"
	// },
  //   {
	// 	title: "Help Center",
	// 	icon: QuestionCircleOutlined,
	// 	path: "/"
	// }
]

export const NavProfile = () => {
  let LoginUserName = localStorage.getItem("UID")
    const history = useHistory()
    const signOutProfile = async () => {
      await auth.signOut();
      notification.open({
        message: 'SUCCESS',
        description:
        'Logout successfully',
        style:{"backgroundColor":"#62c7cb","color":"#fff","fontSize":"30px"}
      });
      history.push('/auth/login')
    }

  const profileImg = "/img/avatars/thumb-1.jpg";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={30} src={profileImg}  />
          <div className="pl-3">
          <span className="text-muted">Admin</span>
            <h5 className="mb-0">
              {LoginUserName}
            </h5>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
        {console.log(auth)}
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
         <Menu.Item key={menuItem.legth + 1} onClick={() => { signOutProfile() }}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, {signOut})(NavProfile)
