import React, { useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from "react-redux";
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'
import { auth } from 'auth/FirebaseAuth';
const PrivateRoute = ({ component: Component, ...rest }) => {

const [isAuthenticated, setIsAuthenticated] = useState(null)  
  useEffect(() => {
    auth.onAuthStateChanged (currentUser => {
            if(currentUser){
              setIsAuthenticated(true)
          } else {
              setIsAuthenticated(false)
          }
        })
  }, [])
  if(isAuthenticated === null){
    return <></>
  }

  return (
    <Route {...rest} render={props =>
      (isAuthenticated) ? (
        <Component {...props} />
        // <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} />
      ) : (
        <Redirect to={`${AUTH_PREFIX_PATH}/login`}/>
      )
    }
    />
  );
};

export default PrivateRoute;