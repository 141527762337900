const dev = {
	API_ENDPOINT_URL: 'https://dev.heysyndee.com/graphql',
	FIREBASE_API_KEY: 'AIzaSyA2oJmLtqz-J9iE-fYGSD64_sLGMIaKeek',
	FIREBASE_AUTH_DOMAIN:"syndee-dev-app.firebaseapp.com",
	FIREBASE_PROJECT_ID:"syndee-dev-app",
	FIREBASE_STORAGE_BUCKET:"syndee-dev-app.appspot.com",
	FIREBASE_MESSAGING_SENDER_ID:"238196239973",
	FIREBASE_APP_ID:"1:238196239973:web:adb066c5fc5746e9f6208c",
};

const prod = {
	API_ENDPOINT_URL: 'https://api.heysyndee.com/graphql',
	FIREBASE_API_KEY: "AIzaSyAWn8jvb1oLPeWxK7dP9b7i1wPVHdgtjOA",
	FIREBASE_AUTH_DOMAIN:"syndee-57800.firebaseapp.com",
	FIREBASE_PROJECT_ID: "syndee-57800",
	FIREBASE_STORAGE_BUCKET:"syndee-57800.appspot.com",
	FIREBASE_MESSAGING_SENDER_ID:"772949856410",
	FIREBASE_APP_ID: "1:772949856410:web:5607663f9534295ae7cf23",
};

const test = {
  API_ENDPOINT_URL: 'https://api.test.com'
};
console.log(process.env);
const getEnv = () => {
	switch (process.env.REACT_APP_NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}
// https://syndee-testing-app.herokuapp.com/graphql
// API_ENDPOINT_URL: 'https://dev.heysyndee.com/graphql',
export const env = getEnv()
